import styled, { css } from '../styled';

import Text from './Text';
import withMargin from '../styled/withMargin';

interface ContentProps {
    title: string;
    subtitle?: string;
    sidebarContent?: React.ReactNode;
}

interface MainProps {
    layout?: 'flex' | 'grid3col';
    children?: React.ReactNode;
    underConstruction?: boolean;
    margin?: string;
}

export const ComingSoon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(p) => p.theme.spacing.xxxl};
    width: 100%;
    height: 100%;
    border: 1px solid ${(p) => p.theme.color.dividerDark};
`;

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: ${(p) => p.theme.spacing.xxl};
    height: 100%;
    justify-content: space-between;
    position: relative;
`;

const SidebarTop = styled.div`
    margin-bottom: ${(p) => p.theme.spacing.xxl};
`;

const SidebarContent = styled.div`
    position: relative;
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: 0;
`;

const UnderConstruction = styled.div<MainProps>`
    ${({ theme }) => css`
        background: #fbd85d;
        border-radius: 6px;
        display: inline-flex;
        border: 0;
        padding: ${theme.spacing.s};
        font-size: ${theme.typography.size.s};
        line-height: 1;
    `}

    ${withMargin};
`;

const Main = styled.main<MainProps>`
    ${({ theme, ...props }) => css`
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: 100%;

        ${props.layout === 'grid3col' &&
        css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: max-content;
            /* gap: ${theme.spacing.s}; */

            @media only screen and (max-width: ${theme.breakpoint.mobile}) {
                border: 0px solid;
                grid-template-columns: repeat(2, 1fr);
            }
        `}
    `};
`;

// Combine props
type Props = ContentProps & MainProps;

const TemplateContent = ({
    title,
    subtitle,
    sidebarContent,
    underConstruction = false,
    children,
    layout = 'flex',
}: Props) => {
    return (
        <>
            <Sidebar>
                <StickyWrapper>
                    <SidebarTop>
                        {subtitle && <Text color="textLight">{subtitle}</Text>}
                        {title && <Text tabIndex={1}>{title}</Text>}
                        {underConstruction && (
                            <UnderConstruction>
                                Work in progress
                            </UnderConstruction>
                        )}
                    </SidebarTop>
                    {sidebarContent && (
                        <SidebarContent tabIndex={1}>
                            {sidebarContent}
                        </SidebarContent>
                    )}
                </StickyWrapper>
            </Sidebar>
            <Main layout={layout}>{children}</Main>
        </>
    );
};

export default TemplateContent;
