import TemplateContent, { ComingSoon } from './TemplateContent';

import { DotMachine1 } from './DotMachine1';
import TemplatePage from './TemplatePage';
import Text from './Text';
import { isMobile } from 'react-device-detect';

export default function PageDotmachine1() {
    return (
        <>
            <TemplatePage layout="2col">
                <TemplateContent
                    title="beat"
                    subtitle="dot-machine 1"
                    layout="grid3col"
                    sidebarContent={
                        <>
                            <Text size="s" margin="0 0 xs 0" color="textLight">
                                how to play
                            </Text>
                            <Text size="s">
                                <ul>
                                    <li>
                                        Hover over a square to start looping a
                                        sound.
                                        <br />
                                    </li>
                                    <li>Click a square to stop its sound.</li>
                                </ul>
                            </Text>
                        </>
                    }
                >
                    {isMobile ? (
                        <ComingSoon>
                            <Text size="s" color="textLight" margin="m 0">
                                <Text color="text" size="s" isInline>
                                    You can play this dot-machine on desktop.
                                </Text>{' '}
                                Coming soon to mobile.
                            </Text>
                        </ComingSoon>
                    ) : (
                        <DotMachine1 />
                    )}
                </TemplateContent>
            </TemplatePage>
        </>
    );
}
