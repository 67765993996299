import { css } from './index';
import { theme } from './theme';

export interface WithMarginProp {
    margin?: string;
}

interface SizeMap {
    [K: string]: string;
}

const sizes: SizeMap = theme.spacing;

export default function withMargin({ margin = '' }: WithMarginProp) {
    const trimmedMargin = margin.trim();

    if (!trimmedMargin) {
        return '';
    }

    const margins: string[] = trimmedMargin
        .split(' ')
        .filter(Boolean)
        .map((sizeStr: string) => {
            if (Object.keys(sizes).includes(sizeStr)) {
                return sizes[sizeStr];
            } else if (sizeStr === '0') {
                return '0';
            } else {
                return '';
            }
        });

    switch (margins.slice(0, 4).length) {
        case 1:
            return css`
                margin: ${margins[0]};
            `;
        case 2:
            return css`
                margin: ${margins[0]} ${margins[1]};
            `;
        case 3:
            return css`
                margin: ${margins[0]} ${margins[1]} ${margins[2]};
            `;
        case 4:
            return css`
                margin: ${margins[0]} ${margins[1]} ${margins[2]} ${margins[3]};
            `;
        default:
            return css`
                margin: 0;
            `;
    }
}
