import { DefaultTheme } from 'styled-components';

export const baseColors = {
    black: '#000000',
    white: '#f4f4f4',
    primary: '#C7C7C7',
    darkGray: '#222222',
    midGray: '#767676',
    lightGray: '#999999',
};

const theme: DefaultTheme = {
    spacing: {
        xs: '2px',
        s: '8px',
        m: '12px',
        l: '16px',
        xl: '24px',
        xxl: '32px',
        xxxl: '46px',
    },
    typography: {
        headerFamily: `'DM Sans', sans-serif`,
        bodyFamily: `'DM Sans', sans-serif`,
        size: {
            s: '0.9rem',
            m: '1rem',
            l: '1.4rem',
            xl: '2rem',
            xxl: '3rem',
        },
        weight: {
            normal: '500',
            bold: '800',
        },
    },
    color: {
        white: baseColors.white,
        text: baseColors.darkGray,
        textLight: baseColors.midGray,
        background: baseColors.white,
        primary: baseColors.primary,
        placeholder: baseColors.lightGray,
        dividerLight: baseColors.white,
        dividerDark: baseColors.primary,
    },
    breakpoint: {
        mobile: '600px',
        tablet: '900px',
    },
};

export { theme };
