import styled, { css } from '../styled';

import { Howler } from 'howler';
import Link from './Link';
import Text from './Text';
import { isMobile } from 'react-device-detect';
import useSound from 'use-sound';

interface Props {
    isLink?: boolean;
    isInformation?: boolean;
    isComingSoon?: boolean;
    subtitle?: string;
    isStart?: boolean;
    isHome?: boolean;
    title?: string;
    isPlayable?: boolean;
    mode?: 'light' | 'dark';
    link?: string;
    sound?: string;
    children?: React.ReactNode;
    hoverBackground?: string;
}

const commonGridStyles = css<Props>`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid ${theme.color.dividerLight};
        border-bottom: 1px solid ${theme.color.dividerLight};
        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            height: 120px;
            border: 0px solid ${theme.color.dividerLight};
        }
    `};
`;

export const GridSpace = styled.div<Props>`
    ${commonGridStyles}
`;

const Title = styled(Text)`
    position: relative;
`;

const ComingSoon = styled.div`
    margin-top: ${(p) => p.theme.spacing.xs};
    text-align: center;
`;

const Dot = styled.div<Props>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: clamp(40px, 5vw, 60px);
    height: clamp(40px, 5vw, 60px);
    border-radius: 10000%;
    box-shadow: 0 0 2px 0 ${(p) => p.theme.color.white};
    border: 1px solid ${(p) => p.theme.color.white};
    z-index: 0;
`;

const Subtitle = styled.div`
    position: relative;
`;

const ContentWrapper = styled.div<Props>`
    ${({ theme, ...props }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: ${theme.color.white};
        ${props.mode === 'dark' &&
        css`
            color: ${theme.color.text};
        `};
    `}
`;

const GridSpaceContent = styled.div<Props>`
    ${({ theme, ...props }) => css`
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        ${props.isPlayable &&
        css`
            &:hover,
            &:focus {
                ${Dot} {
                    background: ${theme.color.white};
                }
            }
        `};
    `};
`;

const GridSpaceLink = styled(Link)<Props>`
    ${({ theme, ...props }) => css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        text-align: center;
        color: ${theme.color.text};
        cursor: pointer;
        padding: ${theme.spacing.xl};
        &:focus {
            outline: 3px solid blue;
        }
        ${props.isComingSoon &&
        css`
            cursor: not-allowed;
        `};
        ${(props.isHome || props.isStart) &&
        css`
            padding: ${theme.spacing.l};
            background: ${theme.color.background};
            ${Subtitle} {
                display: none;
            }
            &:hover,
            &:focus {
                ${Title} {
                    display: none;
                }

                ${Subtitle} {
                    display: block;
                }
            }

            @media only screen and (max-width: ${theme.breakpoint.mobile}) {
                background: ${theme.color.white};
            }
        `};

        ${props.isInformation &&
        css`
            padding: ${theme.spacing.l};
            background: ${theme.color.background};
            cursor: default;

            @media only screen and (max-width: ${theme.breakpoint.mobile}) {
                background: ${theme.color.white};
            }
        `};

        ${props.isLink &&
        css`
            ${Title}, ${ComingSoon}, ${ContentWrapper} {
                display: none;
            }

            &:hover,
            &:focus {
                background: ${theme.color.white} url(${props.hoverBackground})
                    no-repeat top left;
                background-size: cover;

                ${Title}, ${ComingSoon}, ${ContentWrapper} {
                    display: flex;
                }

                ${Dot} {
                    display: none;
                }
            }
        `};

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            padding: ${theme.spacing.l};

            ${Title}, ${ComingSoon}, ${ContentWrapper} {
                display: flex;
            }
            ${Dot} {
                display: none;
            }
            background: ${theme.color.white} url(${props.hoverBackground})
                no-repeat top left;
            background-size: cover;
        }
    `};
`;

export const GridDot = ({
    isLink,
    title,
    subtitle,
    isStart,
    isHome,
    isInformation,
    isComingSoon = false,
    link,
    isPlayable,
    hoverBackground,
    sound,
    mode = 'light',
}: Props) => {
    const [play, { stop }] = useSound(sound!, Howler.mute);

    const commonProps = {
        sound,
        onFocus: () => play(),
        onMouseEnter: () => play(),
        onClick: () => stop(),
    };

    if (isHome) {
        return (
            <GridSpaceLink href={link ? link : '#'} isHome {...commonProps}>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </GridSpaceLink>
        );
    }
    if (isInformation) {
        return (
            <GridSpaceLink href={link ? link : '#'} isInformation>
                <Title>{title}</Title>
            </GridSpaceLink>
        );
    }

    if (isStart) {
        return (
            <GridSpaceLink href={link!} isStart {...commonProps}>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </GridSpaceLink>
        );
    }

    if (isLink) {
        return (
            <GridSpaceLink
                href={link!}
                hoverBackground={hoverBackground}
                isLink
                isComingSoon={isComingSoon}
                mode={mode}
                {...commonProps}
            >
                <Dot isPlayable={isPlayable} />
                <ContentWrapper mode={mode}>
                    <Text>{title}</Text>
                    {isComingSoon && (
                        <ComingSoon>
                            <Text>coming soon</Text>
                        </ComingSoon>
                    )}
                </ContentWrapper>
            </GridSpaceLink>
        );
    }

    if (isPlayable) {
        return (
            <GridSpaceContent isPlayable {...commonProps}>
                <Dot isPlayable={isPlayable} />
            </GridSpaceContent>
        );
    }

    if (isMobile && isPlayable) {
        return (
            <GridSpaceContent isPlayable onTouchStart={() => play()}>
                <Dot isPlayable={isPlayable} />
            </GridSpaceContent>
        );
    }

    return null;
};
