import ListOf from './ListOfContent';
import { PortableText } from '@portabletext/react';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import { useCMSPageData } from '../utils';

export default function PageRecordings() {
    const pageData = useCMSPageData(
        `*[_type == "pageIntroduction" && pageName == "Recordings"] {
                    pageName,
                    pageDescription,
           }`
    );

    return (
        <>
            <TemplatePage layout="2col">
                {pageData ? (
                    <TemplateContent
                        subtitle="discography"
                        title="recordings"
                        layout="grid3col"
                        sidebarContent={
                            <>
                                {pageData.pageDescription && (
                                    <Text size="s" margin="0 0 xxl 0">
                                        <PortableText
                                            value={pageData.pageDescription}
                                        />
                                    </Text>
                                )}
                            </>
                        }
                    >
                        <ListOf contentType="recordings" />
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
