import * as React from 'react';

import styled, { css } from '../styled';

import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
    children: React.ReactNode;
    className?: string;
    href?: string;
    margin?: string;
    isDisabled?: boolean;
    onFocus?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
    onMouseEnter?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
    onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
    shouldOpenInNewWindow?: boolean;
}

const linkStyles = () => css``;

const StyledAnchorLink = styled((props: LinkProps) => {
    const { children, href, shouldOpenInNewWindow, isDisabled, ...otherProps } =
        props;
    return (
        <a {...otherProps} href={href}>
            {children}
        </a>
    );
})`
    ${linkStyles}
`;

const StyledRouterLink = styled((props: LinkProps) => {
    const { children, shouldOpenInNewWindow, href, isDisabled, ...otherProps } =
        props;
    return (
        <RouterLink {...otherProps} to={href ? href : '#'}>
            {children}
        </RouterLink>
    );
})<LinkProps>`
    ${linkStyles}
`;

const Link = (props: LinkProps) => {
    const { href, ...commonProps } = props;
    const newWindowProps = props.shouldOpenInNewWindow
        ? {
              target: '_blank',
              rel: 'noopener noreferrer',
          }
        : {};

    if (href?.includes('://')) {
        return (
            <StyledAnchorLink
                href={href ? href : '#'}
                {...newWindowProps}
                {...commonProps}
            />
        );
    }

    return (
        <StyledRouterLink href={href} {...newWindowProps} {...commonProps} />
    );
};

export default Link;
