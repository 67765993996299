import styled, { css } from '../styled';

import React from 'react';
import withMargin from '../styled/withMargin';

interface SoundIconProps {
    sound?: 'muted' | 'unmuted';
    color?: 'inherit' | 'white' | 'text' | 'textLight';
    margin?: string;
}

const StyledSvg = styled.svg<SoundIconProps>`
    ${({ theme, ...props }) => css`
        ${props.color !== 'inherit' &&
        css`
            fill: ${theme.color[props.color]};
        `};
    `}
    ${withMargin};
`;

const IconSound: React.FC<SoundIconProps> = ({
    sound,
    color = 'textLight',
    margin,
}) => {
    const svgPaths = {
        muted: (
            <path
                id="unmuted"
                d="M18.7531 20L17.6079 18.5059C20.2797 17.0787 21.8749 14.646 21.8749 11.999C21.8749 9.35206 20.28 6.91989 17.6082 5.49496L18.7525 4C22.0384 5.75237 24 8.74257 24 11.999C24 15.2555 22.0384 18.2451 18.7531 20ZM19.1633 11.999C19.1633 13.1223 18.7571 14.2012 17.9888 15.119C17.4911 15.7136 16.8648 16.2129 16.1443 16.5968L15 15.1041C16.2603 14.4379 17.0382 13.2836 17.0382 11.999C17.0382 10.7144 16.2623 9.56326 15.005 8.89646L16.1479 7.40289C16.8671 7.78653 17.4918 8.28503 17.9888 8.87875C18.7571 9.79659 19.1633 10.8758 19.1633 11.999Z"
            />
        ),
        unmuted: (
            <path
                id="muted"
                d="M24 8.37417L22.6258 7L19.5 10.1258L16.3742 7L15 8.37417L18.1258 11.5L15 14.6258L16.3742 16L19.5 12.8742L22.6258 16L24 14.6258L20.8742 11.5L24 8.37417Z"
            />
        ),
    };

    return (
        <StyledSvg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            color={color}
            margin={margin}
        >
            <g id="icon--mute-unmute">
                {svgPaths[sound]}
                <path id="speaker" d="M13 23L7 17H0V7H7L13 1V23Z" />
            </g>
        </StyledSvg>
    );
};

export default IconSound;
