import ListOf from './ListOfContent';
import { PortableText } from '@portabletext/react';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import { useCMSPageData } from '../utils';

export default function PageFilms() {
    const pageData = useCMSPageData(
        `*[_type == "pageIntroduction" && pageName == "Films"] {
                    pageName,
                    pageDescription,
           }`
    );

    return (
        <>
            <TemplatePage layout="2col">
                {pageData ? (
                    <TemplateContent
                        subtitle="media"
                        title="films"
                        layout="grid3col"
                        sidebarContent={
                            <>
                                <Text size="s" margin="0 0 xxl 0">
                                    {pageData.pageDescription ? (
                                        <PortableText
                                            value={pageData.pageDescription}
                                        />
                                    ) : (
                                        <>
                                            A portfolio of work that has been
                                            created in conjunction with the
                                            moving image. It divides roughly
                                            into two categories, with
                                            self-initiated works that integrate
                                            creative activity in visual and
                                            audio fields, such as the ‘Abstract
                                            X’ series, and those based on ‘live
                                            spaced’ musical works that have been
                                            performed in previous contexts, such
                                            as ‘Shekere’ and ‘Rimshot’. There is
                                            a listing with links to film works
                                            featuring a compositional,
                                            directing, or performance
                                            contribution.{' '}
                                        </>
                                    )}
                                </Text>
                            </>
                        }
                    >
                        <ListOf contentType="films" />
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
