import { PortableText } from '@portabletext/react';
import StyledLi from './StyledLi';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import YourTub from './YourTub';
import { useCMSPageData } from '../utils';
import { useParams } from 'react-router-dom';

export default function PageSingleProject() {
    const { slug } = useParams<{ slug: string }>();

    const projectData = useCMSPageData(
        `*[_type == "project" && slug.current == "${slug}"] {
                    projectName,
                    projectDescription,
                    slug,
                    thumbnail,
                    collaborators,
                    'youtubeVideo': youtubeVideo[0].url,
                    projectActiveSince,
           }`
    );

    if (!PageSingleProject)
        return <>this is not the droid you are looking for</>;

    return (
        <>
            <TemplatePage layout="2col">
                {projectData ? (
                    <TemplateContent
                        subtitle="project"
                        title={projectData.projectName}
                        sidebarContent={
                            <>
                                {projectData.projectActiveSince && (
                                    <Text
                                        color="textLight"
                                        size="s"
                                        margin="0 0 m 0"
                                    >
                                        active since{' '}
                                        <Text color="text" size="s">
                                            {projectData.projectActiveSince.substring(
                                                0,
                                                4
                                            )}
                                        </Text>
                                    </Text>
                                )}
                                {projectData.collaborators && (
                                    <Text color="textLight" size="s">
                                        collaborators{' '}
                                        <Text color="text" size="s">
                                            {projectData.collaborators.map(
                                                (collaboratorName, index) => (
                                                    <StyledLi key={index}>
                                                        {collaboratorName}
                                                    </StyledLi>
                                                )
                                            )}
                                        </Text>
                                    </Text>
                                )}
                            </>
                        }
                    >
                        {projectData.youtubeVideo && (
                            <YourTub
                                url={projectData.youtubeVideo}
                                margin="0 0 xl 0"
                            />
                        )}
                        <Text>
                            <PortableText
                                value={projectData.projectDescription}
                            />
                        </Text>
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
