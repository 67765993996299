import { PortableText } from '@portabletext/react';
import StyledLi from './StyledLi';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import YourTub from './YourTub';
import { useCMSPageData } from '../utils';
import { useParams } from 'react-router-dom';

export default function PageSingleFilm() {
    const { slug } = useParams<{ slug: string }>();

    const filmData = useCMSPageData(
        `*[_type == "film" && slug.current == "${slug}"] {
                    filmName,
                    filmDescription,
                    slug,
                    thumbnail,
                    directedBy,
                    performedBy,
                    'youtubeVideoOld': youtubeVideo[1].url,
                    'youtubeVideoNew': youtubeVideo[0].url,
                    filmCompletedYear,
           }`
    );

    if (!PageSingleFilm) return <>this is not the droid you are looking for</>;

    return (
        <>
            <TemplatePage layout="2col">
                {filmData ? (
                    <TemplateContent
                        subtitle="film"
                        title={filmData.filmName}
                        sidebarContent={
                            <>
                                {filmData.performedBy && (
                                    <Text
                                        color="textLight"
                                        size="s"
                                        margin="0 0 m 0"
                                    >
                                        music by{' '}
                                        <Text color="text" size="s">
                                            {filmData.performedBy.map(
                                                (collaboratorName, index) => (
                                                    <StyledLi key={index}>
                                                        {collaboratorName}
                                                    </StyledLi>
                                                )
                                            )}
                                        </Text>
                                    </Text>
                                )}
                                {filmData.directedBy && (
                                    <Text
                                        color="textLight"
                                        size="s"
                                        margin="0 0 m 0"
                                    >
                                        directed by{' '}
                                        <Text color="text" size="s">
                                            {filmData.directedBy}
                                        </Text>
                                    </Text>
                                )}
                                {filmData.filmCompletedYear && (
                                    <Text color="textLight" size="s">
                                        completed{' '}
                                        <Text color="text" size="s">
                                            {filmData.filmCompletedYear.substring(
                                                0,
                                                4
                                            )}
                                        </Text>
                                    </Text>
                                )}
                            </>
                        }
                    >
                        {/* Sanity content hack due to API changing between
                        versions. May be a cleaner way */}
                        {filmData.youtubeVideoOld && (
                            <YourTub
                                url={filmData.youtubeVideoOld}
                                margin="0 0 xxl 0"
                            />
                        )}
                        {filmData.youtubeVideoNew && (
                            <YourTub
                                url={filmData.youtubeVideoNew}
                                margin="0 0 xxl 0"
                            />
                        )}

                        <Text>
                            <PortableText value={filmData.filmDescription} />
                        </Text>
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
