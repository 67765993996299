import * as React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div<{ margin?: string }>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
`;

const GRIDSQUARESIZE = '225px';

const GridSquare = styled.div`
    height: ${GRIDSQUARESIZE};
    width: ${GRIDSQUARESIZE};
`;

export interface ListProps<I> {
    readonly items: I[];
    readonly keyExtractor: (item: I, index: number) => string;
    readonly renderItem: (item: I, index: number) => React.ReactNode;
    readonly className?: string;
    readonly renderEmpty?: () => React.ReactNode;
}

function GridItems<I>(props: ListProps<I>) {
    let content = props.items.map((item, index) => {
        return (
            <GridSquare key={props.keyExtractor(item, index)}>
                {props.renderItem(item, index)}
            </GridSquare>
        );
    });

    return <Wrapper className={props.className}>{content}</Wrapper>;
}

export default GridItems;
