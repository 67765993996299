import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import Text from './Text';
import { isMobile } from 'react-device-detect';

interface Props {
    image?: string;
    link?: string;
    title?: string;
    date?: string;
}

export const Title = styled(Text)`
    position: relative;
`;

export const Subtitle = styled(Text)`
    position: relative;
`;

const PageGridArrayItem = styled(Link)<Props>`
    ${({ theme, ...props }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        overflow: hidden;
        text-decoration: none;
        text-align: center;

        width: 100%;
        aspect-ratio: 1 / 1;
        outline: none;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background: ${props.image
                ? `url(${props.image})`
                : `${theme.color.textLight}`};
            background-size: cover;
            filter: contrast(120%);
        }
        &:hover:before {
            filter: brightness(50%) contrast(200%);
        }
        &:focus:before {
            filter: brightness(50%) contrast(200%);
            box-shadow: inset white 0px 0px 0px 10px;
        }

        &:nth-child(2n):before {
            background: ${props.image
                ? `url(${props.image})`
                : `${theme.color.placeholder}`};
        }

        // Hide title and subtitle, unless hovered or if there's a background image
        ${props.image &&
        css`
            ${Title}, ${Subtitle} {
                display: none;
            }
            &:hover,
            &:focus {
                ${Title}, ${Subtitle} {
                    display: block;
                }
            }
        `}

        // Show titles on mobile
        ${isMobile &&
        css`
            &:before {
                filter: brightness(70%) contrast(160%);
            }

            ${Title}, ${Subtitle} {
                display: block;
            }
        `};
    `}
`;

export default PageGridArrayItem;
