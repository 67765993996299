import styled, { css } from 'styled-components';

import DotMachineImg1 from '../assets/dotmachines/home/1/off.jpg';
import DotMachineImg2 from '../assets/dotmachines/home/2/off.jpg';
import DotMachineImg3 from '../assets/dotmachines/home/3/off.jpg';
import DotMachineImg4 from '../assets/dotmachines/home/4/off.jpg';
import DotMachineImg5 from '../assets/dotmachines/home/5/off.jpg';
import DotMachineImg6 from '../assets/dotmachines/home/6/off.jpg';
import DotMachineImg7 from '../assets/dotmachines/home/7/off.jpg';
import DotMachineImg8 from '../assets/dotmachines/home/8/off.jpg';
import DotMachineImg9 from '../assets/dotmachines/home/9/off.jpg';
import GridItems from './GridItems';
import { Link } from 'react-router-dom';

interface DotMachineProps {
    id: string;
    image: string;
    link?: string;
    isComingSoon?: boolean;
    interItemBorder?: boolean; // Add small borders between items in the grid
}

const GridItemContent = styled(Link)<DotMachineProps>`
    ${({ theme, ...props }) => css`
        display: flex;
        text-decoration: none;
        width: 100%;
        opacity: 0.7;
        aspect-ratio: 1 / 1;
        background: url(${props.image});
        background-size: contain;
        cursor: pointer;
        box-shadow: ${props.interItemBorder
            ? `inset 0 0 0 0.5px ${theme.color.dividerDark}`
            : 'none'};

        &:hover {
            opacity: 1;
        }

        ${props.isComingSoon &&
        css`
            opacity: 0.3;
            cursor: not-allowed;
            &:hover {
                opacity: 0.3;
            }
        `};
    `};
`;

const dotMachineHomeGrid: DotMachineProps[] = [
    {
        id: '1',
        image: DotMachineImg1,
        link: '/dot-machine-1',
    },
    {
        id: '2',
        image: DotMachineImg2,
        isComingSoon: true,
    },
    {
        id: '3',
        image: DotMachineImg3,
        isComingSoon: true,
    },
    {
        id: '4',
        image: DotMachineImg4,
        isComingSoon: true,
    },
    {
        id: '5',
        image: DotMachineImg5,
        isComingSoon: true,
    },
    {
        id: '6',
        image: DotMachineImg6,
        isComingSoon: true,
    },
    {
        id: '7',
        image: DotMachineImg7,
        isComingSoon: true,
    },
    {
        id: '8',
        image: DotMachineImg8,
        isComingSoon: true,
    },
    {
        id: '9',
        image: DotMachineImg9,
        isComingSoon: true,
    },
];

const GridItem = ({
    id,
    image,
    link,
    interItemBorder = true,
    isComingSoon,
}: DotMachineProps) => {
    return (
        <GridItemContent
            id={id}
            image={image}
            isComingSoon={isComingSoon}
            to={link ? link : '#'}
            tabIndex={1}
            interItemBorder={interItemBorder}
        />
    );
};

export function DotMachineHome() {
    return (
        <GridItems
            items={dotMachineHomeGrid}
            keyExtractor={(i) => i.id}
            renderItem={(i) => <GridItem {...i} />}
        />
    );
}
