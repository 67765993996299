import * as React from 'react';

import styled, { css } from '../styled/index';

import withMargin from '../styled/withMargin';

/**
 * We whitelist some colors to avoid getting into a situation
 * where we, for example, have 6 different grays.
 */

interface StyledTextProps {
    color: 'inherit' | 'white' | 'text' | 'textLight';
    isInline: boolean;
    isBold: boolean;
    isUppercase: boolean;
    size: 's' | 'm' | 'l';
    margin?: string;
}

const StyledText = styled('span')<StyledTextProps>`
    ${({ theme, ...props }) => css`
        display: block;
        line-height: 1.4rem;

        font-family: ${theme.typography.bodyFamily};
        font-size: ${theme.typography.size[props.size]};
        display: ${props.isInline ? 'inline' : 'block'};
        font-weight: ${props.isBold
            ? theme.typography.weight.bold
            : theme.typography.weight.normal};
        z-index: 1;
        ${props.isUppercase &&
        css`
            text-transform: uppercase;
        `};
        ${props.color !== 'inherit' &&
        css`
            color: ${theme.color[props.color]};
        `};

        p {
            margin: 0 0 ${theme.spacing.xl};

            &:only-child {
                margin: 0;
            }
        }
    `};

    ${withMargin};
`;

export interface TextProps {
    children: React.ReactNode;
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    isInline?: boolean;
    isBold?: boolean;
    isUppercase?: boolean;
    size?: 'xs' | 's' | 'm';
    color?: string;
    margin?: string;
    className?: string;
    tabIndex?: number;
}

const Text = ({
    children,
    as = 'span',
    color = 'inherit',
    isInline = false,
    isBold = false,
    isUppercase = false,
    margin,
    size = 'm',
    className,
    tabIndex,
}: TextProps) => {
    return (
        <StyledText
            as={as}
            color={color}
            margin={margin}
            $isInline={isInline}
            $isBold={isBold}
            $isUppercase={isUppercase}
            size={size}
            className={className}
            tabIndex={tabIndex}
        >
            {children}
        </StyledText>
    );
};

export default Text;
