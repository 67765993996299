import HomeGridSpaces from './GridSpacesHome';
import { PrefetchHoverImages } from './GridSpacesHome';
import TemplatePage from './TemplatePage';
import { css } from '../styled';
import { getRandomImage } from '../utils';
import styled from 'styled-components';

interface Props {
    randomBackgroundImage: string;
}

const GridBackground = styled.div<Props>`
    ${({ theme, randomBackgroundImage }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: ${(p) => p.theme.spacing.xxl};
        /* aspect-ratio: auto 4 / 3; */
        background: ${theme.color.background} url(${randomBackgroundImage});
        background-size: cover;
        background-position: center center;

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            overflow-y: scroll;
            margin: 0;
            width: 100%;
            min-height: 100%;
            padding: ${(p) => p.theme.spacing.l};
        }

        @media only screen and (max-width: ${theme.breakpoint.tablet}) {
            width: 100%;
        }
    `}
`;

const Grid = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        height: 100%;
        border-top: 1px solid ${theme.color.dividerLight};
        border-right: 1px solid ${theme.color.dividerLight};
        font-size: 1em;

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            height: auto;
            grid-template-rows: auto-flow;
            grid-template-columns: repeat(2, 1fr);
            gap: ${theme.spacing.m};
            border: 0;
        }
    `}
`;

export default function Home() {
    const randomBackgroundImage = getRandomImage();

    return (
        <>
            <TemplatePage>
                <GridBackground randomBackgroundImage={randomBackgroundImage}>
                    <PrefetchHoverImages />
                    <Grid>
                        <HomeGridSpaces />
                    </Grid>
                </GridBackground>
            </TemplatePage>
        </>
    );
}
