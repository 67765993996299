import React from 'react';
import SoundController from './SoundController';
import { css } from '../styled';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
    layout?: 'fullPageGrid' | '2col';
}

const PageWrapper = styled.div<Props>`
    position: relative;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ContentWrapper = styled.div<Props>`
    ${({ theme, ...props }) => css`
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;

        max-width: 1000px;
        max-height: 750px;
        min-height: 500px;
        margin: ${theme.spacing.xxxl} ${theme.spacing.xxxl} ${theme.spacing.m}
            ${theme.spacing.xxxl};
        padding: ${theme.spacing.xxl};
        overflow: auto;

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            padding: 0;
            margin: 0;
            width: 100%;
            min-height: 100vh;
        }

        @media only screen and (max-width: ${theme.breakpoint.tablet}) {
            width: 100%;
            margin: 0;
        }

        ${props.layout === '2col' &&
        css`
            display: grid;
            grid-template-columns: minmax(auto, 260px) 2fr;
            gap: ${theme.spacing.xl};
            border: 1px solid ${theme.color.dividerDark};

            @media only screen and (max-width: ${theme.breakpoint.mobile}) {
                border: 0;
                display: flex;
                flex-direction: column;
                padding: ${theme.spacing.xxl};

                > div {
                    height: auto;
                    margin: 0 0 ${theme.spacing.xl} 0;
                }
            }
        `};
    `}
`;

export default function TemplatePage({
    children,
    layout = 'fullPageGrid',
}: Props) {
    return (
        <>
            <PageWrapper layout={layout}>
                <ContentWrapper layout={layout}>{children}</ContentWrapper>
                {isMobile ? null : <SoundController />}
            </PageWrapper>
        </>
    );
}
