import { PortableText } from '@portabletext/react';
import StyledLi from './StyledLi';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import YourTub from './YourTub';
import styled from '../styled';
import { useCMSPageData } from '../utils';
import { useParams } from 'react-router-dom';
import withMargin from '../styled/withMargin';

interface SpotifyPlayerProps {
    className?: string;
    margin?: string;
    spotifyURL: string;
}

const StyledSpotifyPlayer = styled.iframe<SpotifyPlayerProps>`
    border: 0;
    border-radius: 12px;
    width: 100%;
    min-height: 380px;
    ${withMargin};
`;

const SpotifyPlayer = ({ className, margin, spotifyURL }) => {
    return (
        <StyledSpotifyPlayer
            className={className}
            margin={margin}
            title="Spotify player"
            spotifyURL={spotifyURL}
            src={`https://open.spotify.com/embed/album/${spotifyURL}`}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
        />
    );
};

export default function PageSingleRecording() {
    const { slug } = useParams<{ slug: string }>();

    const recordingData = useCMSPageData(
        `*[_type == "recording" && slug.current == "${slug}"] {
            recordingName,
            recordingDescription,
            slug,
            spotifyURL,
            thumbnail,
                    releaseYear,
                    collaborators,
                    'youtubeVideo': youtubeVideo[1].url,
           }`
    );

    if (!PageSingleRecording)
        return <>this is not the droid you are looking for</>;

    return (
        <>
            <TemplatePage layout="2col">
                {recordingData ? (
                    <TemplateContent
                        subtitle="recording"
                        title={recordingData.recordingName}
                        sidebarContent={
                            <>
                                {recordingData.releaseYear && (
                                    <Text
                                        color="textLight"
                                        size="s"
                                        margin="0 0 m 0"
                                    >
                                        released{' '}
                                        <Text color="text" size="s">
                                            {recordingData.releaseYear.substring(
                                                0,
                                                4
                                            )}
                                        </Text>
                                    </Text>
                                )}
                                {recordingData.collaborators && (
                                    <Text color="textLight" size="s">
                                        collaborators{' '}
                                        <Text color="text" size="s">
                                            {recordingData.collaborators.map(
                                                (collaboratorName, index) => (
                                                    <StyledLi key={index}>
                                                        {collaboratorName}
                                                    </StyledLi>
                                                )
                                            )}
                                        </Text>
                                    </Text>
                                )}
                            </>
                        }
                    >
                        {recordingData.youtubeVideo && (
                            <YourTub
                                url={recordingData.youtubeVideo}
                                margin="0 0 xl 0"
                            />
                        )}
                        {recordingData.spotifyURL && (
                            <SpotifyPlayer
                                margin="0 0 xxl 0"
                                spotifyURL={recordingData.spotifyURL}
                                className=""
                            />
                        )}
                        {recordingData.recordingDescription && (
                            <Text>
                                <PortableText
                                    value={recordingData.recordingDescription}
                                />
                            </Text>
                        )}
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
