import PageGridArrayItem, { Subtitle, Title } from './PageGridArrayItem';
import { urlFor, useCMSArrayData } from '../utils';

export default function ListOf({ contentType }) {
    let dataQuery;
    let childComponent;
    switch (contentType) {
        case 'projects':
            dataQuery = `*[_type == "project"]{
                projectName,
                projectDescription,
                slug,
                thumbnail,
            }`;
            childComponent = (item, index) => (
                <PageGridArrayItem
                    key={index}
                    to={`/projects/${item.slug.current}`}
                    image={
                        item.thumbnail &&
                        urlFor(item.thumbnail).width(230).url()
                    }
                    tabIndex={1}
                    aria-label={`Project name: ${item.projectName}`}
                >
                    <Title color="white" margin="0 xl">
                        {item.projectName}
                    </Title>
                </PageGridArrayItem>
            );
            break;
        case 'recordings':
            dataQuery = `*[_type == "recording"] | order(recordingName desc) | order(releaseYear desc){
                recordingName,
                recordingDescription,
                releaseYear,
                slug,
                thumbnail,
            }`;
            childComponent = (item, index) => (
                <PageGridArrayItem
                    key={index}
                    to={`/recordings/${item.slug.current}`}
                    image={
                        item.thumbnail &&
                        urlFor(item.thumbnail).width(230).url()
                    }
                    tabIndex={1}
                    aria-label={`Recording name: ${item.recordingName} ${
                        item.releaseYear
                            ? item.releaseYear.substring(0, 4)
                            : null
                    }`}
                >
                    {' '}
                    <Title color="white" margin="0 xl">
                        {item.recordingName}
                    </Title>
                    {item.releaseYear ? (
                        <Subtitle color="white" size="s">
                            {item.releaseYear.substring(0, 4)}
                        </Subtitle>
                    ) : null}
                </PageGridArrayItem>
            );
            break;
        case 'films':
            dataQuery = `*[_type == "film"] | order(filmName desc) | order(filmCompletedYear desc) {
                filmName,
                slug,
                thumbnail,
                filmCompletedYear
            }`;
            childComponent = (item, index) => (
                <PageGridArrayItem
                    key={index}
                    to={`/films/${item.slug.current}`}
                    image={
                        item.thumbnail &&
                        urlFor(item.thumbnail).width(230).url()
                    }
                    tabIndex={1}
                    aria-label={`Film name: ${item.filmName} ${
                        item.filmCompletedYear
                            ? item.filmCompletedYear.substring(0, 4)
                            : null
                    }`}
                >
                    <Title color="white">{item.filmName}</Title>
                    {item.filmCompletedYear ? (
                        <Subtitle color="white" size="s">
                            {item.filmCompletedYear.substring(0, 4)}
                        </Subtitle>
                    ) : null}
                </PageGridArrayItem>
            );
            break;
        default:
            dataQuery = '';
            childComponent = () => null;
    }

    const contentData = useCMSArrayData(dataQuery);

    return (
        <>
            {contentData &&
                contentData.map((item, index) => childComponent(item, index))}
        </>
    );
}
