import ListOf from './ListOfContent';
import { PortableText } from '@portabletext/react';
import StyledLi from './StyledLi';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';
import { useCMSPageData } from '../utils';

export default function PageProjects() {
    const pageData = useCMSPageData(
        `*[_type == "pageIntroduction" && pageName == "Projects"] {
                    pageName,
                    pageDescription,
                    currentProjects,
           }`
    );

    return (
        <>
            <TemplatePage layout="2col">
                {pageData ? (
                    <TemplateContent
                        subtitle="current"
                        title="projects"
                        layout="grid3col"
                        sidebarContent={
                            <>
                                {pageData.pageDescription && (
                                    <Text size="s" margin="0 0 xxl 0">
                                        <PortableText
                                            value={pageData.pageDescription}
                                        />
                                    </Text>
                                )}
                                {pageData.currentProjects && (
                                    <Text color="textLight" size="s">
                                        active projects{' '}
                                        <Text color="text" size="s">
                                            {pageData.currentProjects.map(
                                                (currentProjectName, index) => (
                                                    <StyledLi key={index}>
                                                        {currentProjectName}
                                                    </StyledLi>
                                                )
                                            )}
                                        </Text>
                                    </Text>
                                )}
                            </>
                        }
                    >
                        <ListOf contentType="projects" />
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
