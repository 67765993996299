import { useEffect, useState } from 'react';

// Import all background images
import backgroundImg1 from '../assets/bg/1.png';
import backgroundImg10 from '../assets/bg/10.png';
import backgroundImg11 from '../assets/bg/11.png';
import backgroundImg12 from '../assets/bg/12.png';
import backgroundImg2 from '../assets/bg/2.png';
import backgroundImg3 from '../assets/bg/3.png';
import backgroundImg4 from '../assets/bg/4.png';
import backgroundImg5 from '../assets/bg/5.png';
import backgroundImg6 from '../assets/bg/6.png';
import backgroundImg7 from '../assets/bg/7.png';
import backgroundImg8 from '../assets/bg/8.png';
import backgroundImg9 from '../assets/bg/9.png';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../client';
import webGlitch10 from '../assets/bg/webglitch10.mp3';
import webGlitch11 from '../assets/bg/webglitch11.mp3';
import webGlitch12 from '../assets/bg/webglitch12.mp3';
import webGlitch13 from '../assets/bg/webglitch13.mp3';
import webGlitch14 from '../assets/bg/webglitch14.mp3';
import webGlitch15 from '../assets/bg/webglitch15.mp3';
import webGlitch16 from '../assets/bg/webglitch16.mp3';
import webGlitch6 from '../assets/bg/webglitch6.mp3';
import webGlitch7 from '../assets/bg/webglitch7.mp3';
import webGlitch8 from '../assets/bg/webglitch8.mp3';
import webGlitch9 from '../assets/bg/webglitch9.mp3';

// Array of background images
const pictureArray = [
    backgroundImg1,
    backgroundImg2,
    backgroundImg3,
    backgroundImg4,
    backgroundImg5,
    backgroundImg6,
    backgroundImg7,
    backgroundImg8,
    backgroundImg9,
    backgroundImg10,
    backgroundImg11,
    backgroundImg12,
];

// Function to select a random image from the pictureArray
export const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * pictureArray.length);
    return pictureArray[randomIndex];
};

export const webGlitches = [
    webGlitch6,
    webGlitch7,
    webGlitch8,
    webGlitch9,
    webGlitch10,
    webGlitch11,
    webGlitch12,
    webGlitch13,
    webGlitch14,
    webGlitch15,
    webGlitch16,
];

// Function to shuffle an array
export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

function randomNoRepeats(array: string[]) {
    var copy = array.slice(0);
    return function () {
        if (copy.length < 1) {
            copy = array.slice(0);
        }
        var index = Math.floor(Math.random() * copy.length);
        var item = copy[index];
        copy.splice(index, 1);
        return item;
    };
}

export const selectedSound = randomNoRepeats(webGlitches);

// Create an instance of imageUrlBuilder
const builder = imageUrlBuilder(sanityClient);

// Function to generate URL for Sanity images
export function urlFor(source) {
    return builder.image(source);
}

// Fetch page data from the Sanity API
export function useCMSPageData(query) {
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(query)
            .then((data) => setPageData(data[0]))
            .catch(console.error);
    }, [query]);

    return pageData;
}

// Fetch an array of items from the Sanity API
export function useCMSArrayData(query) {
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(query)
            .then((data) => setPageData(data))
            .catch(console.error);
    }, [query]);

    return pageData;
}
