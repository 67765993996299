import * as React from 'react';

import styled, { css } from '../styled';

import withMargin from '../styled/withMargin';

export type HeadingSize = 'xxl' | 'xl' | 'l';

interface StyledHeadingProps {
    size: HeadingSize;
    margin?: string;
    isBold: boolean;
}

const StyledHeading = styled('h1')<StyledHeadingProps>`
    line-height: 1.3;

    ${({ theme, ...props }) => css`
        font-family: ${theme.typography.bodyFamily};
        font-weight: ${props.isBold
            ? theme.typography.weight.bold
            : theme.typography.weight.normal};
        color: ${theme.color.text};

        ${props.size === 'xl' &&
        css`
            font-size: ${theme.typography.size.xl};
        `};
        ${props.size === 'l' &&
        css`
            font-size: ${theme.typography.size.l};
        `};
    `};

    ${withMargin};
`;

export interface HeadingProps {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    children: React.ReactNode;
    size?: HeadingSize;
    margin?: string;
    isUppercase?: boolean;
    isBold?: boolean;
    className?: string;
    id?: string;
}

type HeadingTagMap = { [key in HeadingSize]: keyof JSX.IntrinsicElements };

const headingTagMap: HeadingTagMap = {
    xxl: 'h1',
    xl: 'h2',
    l: 'h3',
};

const Heading = ({
    children,
    margin,
    className,
    isBold,
    id,
    as,
    size = 'l',
}: HeadingProps) => {
    return (
        <StyledHeading
            as={as || headingTagMap[size]}
            size={size}
            margin={margin}
            isBold={isBold}
            className={className}
            id={id}
        >
            {children}
        </StyledHeading>
    );
};

export default Heading;
