import TemplateContent, { ComingSoon } from './TemplateContent';

import { DotMachineHome } from './DotMachineHome';
import { PortableText } from '@portabletext/react';
import TemplatePage from './TemplatePage';
import Text from './Text';
import { isMobile } from 'react-device-detect';
import { useCMSPageData } from '../utils';

export default function PageDotmachines() {
    const pageData =
        useCMSPageData(`*[_type == "pageIntroduction" && pageName == "dot-machines"] {
                    pageName,
                    pageDescription,
           }`);

    return (
        <>
            <TemplatePage layout="2col">
                {pageData ? (
                    <TemplateContent
                        subtitle="instrument"
                        title="dot-machines"
                        layout="grid3col"
                        underConstruction
                        sidebarContent={
                            <>
                                {pageData.pageDescription ? (
                                    <Text size="s" margin="0 0 xxl 0">
                                        <PortableText
                                            value={pageData.pageDescription}
                                        />
                                    </Text>
                                ) : (
                                    <Text size="s" margin="0 0 xxl 0">
                                        Use the computer in performance as a
                                        sample-player, score, possible visual
                                        display. Conventional notation is a
                                        guide. <p /> Audio is pre-mixed and can
                                        be integrated using simple or complex
                                        systems.
                                    </Text>
                                )}
                                <Text
                                    size="s"
                                    margin="0 0 xs 0"
                                    color="textLight"
                                >
                                    tempo
                                </Text>
                                <Text size="s" margin="0 0 m 0">
                                    80bpm (also 40/160)
                                </Text>
                                <Text
                                    size="s"
                                    margin="0 0 xs 0"
                                    color="textLight"
                                >
                                    duration
                                </Text>
                                <Text size="s" margin="0 0 m 0">
                                    minimum 10 minutes
                                </Text>
                            </>
                        }
                    >
                        {isMobile ? (
                            <ComingSoon>
                                <Text size="s" color="textLight" margin="m 0">
                                    <Text color="text" size="s">
                                        You can play this dot-machine on
                                        desktop.
                                    </Text>{' '}
                                    Coming soon to mobile.
                                </Text>
                            </ComingSoon>
                        ) : (
                            <DotMachineHome />
                        )}
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
