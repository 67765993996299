import withMargin, { WithMarginProp } from '../styled/withMargin';

import ReactPlayer from 'react-player';
import styled from '../styled';

interface ComponentProps {
    url?: string;
}
type Props = ComponentProps & WithMarginProp;

const Wrapper = styled.div<Props>`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    background: ${(p) => p.theme.color.primary};

    ${withMargin};
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
`;

const LoadingWrapper = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding-bottom: 56.25%;

    .lds-ring {
        position: relative;
        opacity: 0.4;
        padding-bottom: 56.25%;
    }

    .lds-ring div {
        box-sizing: border-box;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1s cubic-bezier(0.3, 0, 0.3, 0.6) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const YourTub = ({ url, margin }: Props) => {
    return (
        <Wrapper margin={margin}>
            <LoadingWrapper>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </LoadingWrapper>
            <StyledReactPlayer
                url={url}
                width="100%"
                height="100%"
                controls={true}
            />
        </Wrapper>
    );
};

export default YourTub;
