import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.main`
    padding: 10vw;
    font: 1.1rem sans-serif;
    min-height: 100vh;
    background: ${(p) => p.theme.color.white};
`;

export default function Index() {
    return (
        <>
            <title>Marimbo | Simon Limbrick</title>
            <meta
                property="og:title"
                content="Marimbo | Simon Limbrick"
                key="title"
            />
            <meta
                property="og:description"
                content="Home of composer and percussionist Simon Limbrick"
                key="og:description"
            />
            <meta
                name="keywords"
                content="phd,research,simon,limbrick,marimbo,apartment,house,contemporary,electronic,electro-acoustic,composer,percussion,marimba,vibraphone,man,jumping"
            />

            <Wrapper>
                <div>
                    marimbo <span style={{ color: '#bbb' }}>|</span> simon
                    limbrick
                </div>
                <div style={{ color: '#777' }}>
                    The site will be back soon, thanks for visiting
                </div>
            </Wrapper>
        </>
    );
}
