import { GridDot, GridSpace } from './GridSpaces';
import { selectedSound, shuffleArray } from '../utils';

import { isMobile } from 'react-device-detect';

// Array of configurations for StartGridSpace components
const startGridConfigurations = [
    { isPlayable: true, sound: selectedSound() },
    {
        isStart: true,
        link: '/home',
        title: isMobile ? `tap to enter` : `click here to enter`,
        sound: selectedSound(),
        subtitle: 'click here to enter',
    },
    {
        isInformation: true,
        title: 'this website plays sound',
        sound: selectedSound(),
    },
    { isPlayable: true, sound: selectedSound() },
    { isPlayable: true, sound: selectedSound() },
    { isPlayable: true, sound: selectedSound() },
    {},
    {},
    {},
];

// Generic component to render StartGridSpace
const StartGridSpace = ({ config }) => {
    return (
        <GridSpace>
            <GridDot {...config} />
        </GridSpace>
    );
};

const StartGridSpaces = () => {
    // Shuffle the array of configurations
    const shuffledConfigurations = shuffleArray(startGridConfigurations);

    return (
        <>
            {shuffledConfigurations.map((config, index) => (
                <StartGridSpace key={index} config={config} />
            ))}
        </>
    );
};

export default StartGridSpaces;
