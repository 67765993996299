import React, { useEffect, useState } from 'react';
import styled, { css } from '../styled';

import { Howler } from 'howler';
import IconSound from './IconSound';
import Text from './Text';

const SoundManagerWrapper = styled.div`
    ${({ theme }) => css`
        background: none;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        width: 100%;
        max-width: 1000px;
        height: auto;

        margin: 0 ${theme.spacing.xl} ${theme.spacing.xxl} ${theme.spacing.xl};
    `}
`;

const StyledButton = styled.button`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        font: inherit;

        &:hover {
            // Targets everything inside the button
            & > * {
                fill: ${theme.color.text};
                color: ${theme.color.text};
            }
        }
    `};
`;

const SoundManager: React.FC = () => {
    const [muted, setMuted] = useState<boolean>(() => {
        // Initialize the state with the value from local storage, or default to false
        return localStorage.getItem('muted') === 'true';
    });

    useEffect(() => {
        // Update local storage whenever the muted state changes
        localStorage.setItem('muted', muted.toString());
        // Update Howler mute state
        Howler.mute(muted);
    }, [muted]);

    const toggleMute = () => {
        // Toggle the muted state
        setMuted((prevMuted) => !prevMuted);
    };
    return (
        <SoundManagerWrapper>
            <StyledButton onClick={toggleMute}>
                {muted ? (
                    <IconSound sound="muted" margin="0 s 0 0" />
                ) : (
                    <IconSound sound="unmuted" margin="0 s 0 0" />
                )}
                <Text isInline size="s" color="textLight">
                    {muted ? 'unmute sound' : 'mute sound'}
                </Text>
            </StyledButton>
        </SoundManagerWrapper>
    );
};

export default SoundManager;
