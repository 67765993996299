import styled, { css } from '../styled';

import StartGridSpaces from './GridSpacesStart';
import TemplatePage from './TemplatePage';
import { getRandomImage } from '../utils';

interface Props {
    randomBackgroundImage: string;
}

const Grid = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - (${theme.spacing.xxl} * 2));
        height: calc(100% - (${theme.spacing.xxl} * 2));
        margin: ${theme.spacing.xxl};

        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        border-top: 1px solid ${theme.color.dividerLight};
        border-right: 1px solid ${theme.color.dividerLight};
        font-size: 1em;

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            height: auto;
            grid-template-rows: auto-flow;
            grid-template-columns: repeat(2, 1fr);
        }
    `}
`;

const GridBackground = styled.div<Props>`
    ${({ theme, randomBackgroundImage }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: ${theme.spacing.xxl};
        /* aspect-ratio: auto 4 / 3; */
        background: ${theme.color.background} url(${randomBackgroundImage});
        background-size: cover;
        background-position: center center;

        @media only screen and (max-width: ${theme.breakpoint.mobile}) {
            overflow-y: scroll;
            margin: 0;
            width: 100%;
            min-height: 100%;
        }

        @media only screen and (max-width: ${theme.breakpoint.tablet}) {
            width: 100%;
        }
    `}
`;

export default function Start() {
    const randomBackgroundImage = getRandomImage();

    return (
        <TemplatePage>
            <GridBackground randomBackgroundImage={randomBackgroundImage}>
                <Grid>
                    <StartGridSpaces />
                </Grid>
            </GridBackground>
        </TemplatePage>
    );
}
