import GridItems from './GridItems';
import DotMachineImg1 from '../assets/dotmachines/1/1/off.jpg';
import DotMachineImg1Hover from '../assets/dotmachines/1/1/on.jpg';
import DotMachineImg2 from '../assets/dotmachines/1/2/off.jpg';
import DotMachineImg2Hover from '../assets/dotmachines/1/2/on.jpg';
import DotMachineImg3 from '../assets/dotmachines/1/3/off.jpg';
import DotMachineImg3Hover from '../assets/dotmachines/1/3/on.jpg';
import DotMachineImg4 from '../assets/dotmachines/1/4/off.jpg';
import DotMachineImg4Hover from '../assets/dotmachines/1/4/on.jpg';
import DotMachineImg5 from '../assets/dotmachines/1/5/off.jpg';
import DotMachineImg5Hover from '../assets/dotmachines/1/5/on.jpg';
import DotMachineImg6 from '../assets/dotmachines/1/6/off.jpg';
import DotMachineImg6Hover from '../assets/dotmachines/1/6/on.jpg';
import DotMachineImg7 from '../assets/dotmachines/1/7/off.jpg';
import DotMachineImg7Hover from '../assets/dotmachines/1/7/on.jpg';
import DotMachineImg8 from '../assets/dotmachines/1/8/off.jpg';
import DotMachineImg8Hover from '../assets/dotmachines/1/8/on.jpg';
import DotMachineImg9 from '../assets/dotmachines/1/9/off.jpg';
import DotMachineImg9Hover from '../assets/dotmachines/1/9/on.jpg';
import DotMachineSound1 from '../assets/dotmachines/1/dotmach1.mp3';
import DotMachineSound2 from '../assets/dotmachines/1/dotmach2.mp3';
import DotMachineSound3 from '../assets/dotmachines/1/dotmach3.mp3';
import DotMachineSound4 from '../assets/dotmachines/1/dotmach4.mp3';
import DotMachineSound5 from '../assets/dotmachines/1/dotmach5.mp3';
import DotMachineSound6 from '../assets/dotmachines/1/dotmach6.mp3';
import DotMachineSound7 from '../assets/dotmachines/1/dotmach7.mp3';
import DotMachineSound8 from '../assets/dotmachines/1/dotmach8.mp3';
import DotMachineSound9 from '../assets/dotmachines/1/dotmach9.mp3';
import styled from 'styled-components';
import useSound from 'use-sound';

interface DotMachineProps {
    id: string;
    image?: string;
    imageHover: string;
    sound: string;
}

const GridItemContent = styled.a<DotMachineProps>`
    display: flex;
    text-decoration: none;
    width: 100%;
    height: 100%;
    background: url(${(p) => p.image});
    background-size: contain;
    cursor: pointer;

    &:hover {
        background: url(${(p) => p.imageHover});
        background-size: contain;
    }
`;

const dotMachine1Grid: DotMachineProps[] = [
    {
        id: '1',
        image: DotMachineImg1,
        imageHover: DotMachineImg1Hover,
        sound: DotMachineSound1,
    },
    {
        id: '2',
        image: DotMachineImg2,
        imageHover: DotMachineImg2Hover,
        sound: DotMachineSound2,
    },
    {
        id: '3',
        image: DotMachineImg3,
        imageHover: DotMachineImg3Hover,
        sound: DotMachineSound3,
    },
    {
        id: '4',
        image: DotMachineImg4,
        imageHover: DotMachineImg4Hover,
        sound: DotMachineSound4,
    },
    {
        id: '5',
        image: DotMachineImg5,
        imageHover: DotMachineImg5Hover,
        sound: DotMachineSound5,
    },
    {
        id: '6',
        image: DotMachineImg6,
        imageHover: DotMachineImg6Hover,
        sound: DotMachineSound6,
    },
    {
        id: '7',
        image: DotMachineImg7,
        imageHover: DotMachineImg7Hover,
        sound: DotMachineSound7,
    },
    {
        id: '8',
        image: DotMachineImg8,
        imageHover: DotMachineImg8Hover,
        sound: DotMachineSound8,
    },
    {
        id: '9',
        image: DotMachineImg9,
        imageHover: DotMachineImg9Hover,
        sound: DotMachineSound9,
    },
];

const GridItem = ({ id, image, imageHover, sound }: DotMachineProps) => {
    const [play, { stop }] = useSound(sound!, { loop: true });

    return (
        <GridItemContent
            id={id}
            image={image}
            imageHover={imageHover}
            tabIndex={1}
            onFocus={() => {
                play();
            }}
            onMouseEnter={() => {
                play();
            }}
            onClick={() => {
                stop();
            }}
            onTouchStart={() => {
                play();
            }}
            sound={sound}
        />
    );
};

export function DotMachine1() {
    return (
        <GridItems
            items={dotMachine1Grid}
            keyExtractor={(i) => i.id}
            renderItem={(i) => <GridItem {...i} />}
        />
    );
}
